import { Alignment, Button, Navbar as BPNavbar } from '@blueprintjs/core';
import React, { useContext } from 'react';

export default function Navbar() {
  return (
    <BPNavbar>
      <BPNavbar.Group align={Alignment.LEFT}>
        <BPNavbar.Heading>rst-lyrics</BPNavbar.Heading>
      </BPNavbar.Group>
    </BPNavbar>
  );
}
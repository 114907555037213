/** @jsx jsx */
import { css, jsx, Global } from "@emotion/core";
import { Classes, Button, Intent, Colors } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";

import Navbar from './Navbar';
import Form from './Form';
import { useContext } from "react";

const globalStyle = css`
  body {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 14px;
    background-color: #293742;
  }
`;

const appStyle = css`
  display: flex;
  width: 600px;
  @media screen and (max-width: 600px) {
      width: 100%;
  };
`;

export const App = () => (
  <section css={appStyle}>
    <Global styles={globalStyle} />
    <div className={Classes.DARK}>
      <Navbar />
      <Form />
    </div>
  </section>
);
